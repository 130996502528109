import Link from 'next/link';
import { useRouter } from 'next/router';
import { useRef } from 'react';

import { useAuth } from '@context/AuthContext';

import Button from '@components/Button/Button';

const Login = () => {
  const router = useRouter();

  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);

  const { logIn } = useAuth();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get email and password input values
    const email = emailRef.current?.value || '';
    const password = passwordRef.current?.value || '';

    try {
      const result = await logIn({ email, password });
      router.push('/');
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <label htmlFor="input-email">Email</label>
        <input id="input-email" type="email" ref={emailRef} />
        <br />

        <label htmlFor="input-password">Password</label>
        <input id="input-password" type="password" ref={passwordRef} />

        <br />

        <Button onClick={handleSubmit} text="Login" />

        <br />
        <br />
        <Link href="/signup">Sign up instead</Link>
      </form>
    </>
  );
};

export default Login;
