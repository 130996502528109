import { GetStaticProps } from 'next';

import Layout from '@components/Layout/Layout';
import Login from '@components/Auth/Login/Login';

type IndexProps = {
  description: string;
  featureFlags: any;
  title: string;
};

const Index = ({ description, featureFlags, title }: IndexProps) => {
  return (
    <Layout
      featureFlags={featureFlags}
      pageDescription={description}
      pageTitle={title}
      pageType="website"
      section="Home"
      siteTitle={title}
      url="/login"
    >
      <Login />
    </Layout>
  );
};

export default Index;

export const getStaticProps: GetStaticProps = async () => {
  const configData = await import(`../siteconfig.json`);

  return {
    props: {
      description: configData.default.description,
      featureFlags: configData.featureFlags,
      title: configData.default.title,
    },
  };
};
